<template>
  <div>
    <v-card rounded="0" style="background-color:black">
      <v-container>
        <h3 class="h3 white--text">A LETTER FROM THE PASTOR</h3>
      </v-container>
    </v-card>
    <v-card rounded="0">
      <v-container class="py-10">
        <p>Dear friends,</p>
        <p>We are living in unprecedented times-a time that calls for us to temporally close our doors for public worship as a result of Covid-19 pandemic. This is a directive from the Ontario Conference of SDA, the Chief Medical Officer and the Government of Ontario.</p>
        <p>In light of that, the hope we have in Jesus to share with others is still alive. And so, we have temporarily moved to ministering on a virtually platform. You are all welcomed to join us on Wednesdays at 7 p.m. for prayer meeting and on Saturdays at 10 a.m. for worship. </p>
        <p>Please follow <a class="text-decoration-none" style="color:green" href="/Zoom">this link</a> to join us for the posted worship sessions.</p>
        <h4 class="my-4"><i>“Fear not, for I am with you; be not dismayed, for I am your God; I will strengthen you, I will help you, I will uphold you with my righteous right hand.” Isaiah 41:10</i></h4>
        <p class="mb-10">
          Sincerely in Christ,<br>
          <i>Pastor Elvis Dumitru</i>
        </p>
      </v-container>
    </v-card>
  </div>
</template>
<script>

export default {
  components: {

  },
  head: {
    title: function () {
      return {
        inner: 'Letter'
      }
    }
  }
}
</script>